import gql from "graphql-tag";

const firm = `
  id
  name
`;

const sensor = `
  id
  name
  is_active
  last_temprature
  last_battery
  last_update
  mac
  power_on_date
  company_id
  company {
    ${firm}
  }
  log_interval
  log_capacity
  signal_strength
  last_gateway_id
  last_gateway {
    mac
  }
  comments
  next_sample
  createdAt
  updatedAt
`;

export const GET_SENSORS_COUNT = gql`
  query getSensorsCount($userId: Int) {
    getSensorsCount(userId: $userId) {
      count
    }
  } 
`;

export const GET_SENSORS = gql`
  query getSensors (
    $pageNo: Int!
    $userId: Int
  ) {
    getSensors (
      pageNo: $pageNo
      userId: $userId
    ) {
      ${sensor}
    }
  } 
`;

export const CHANGE_SENSOR_COMPANY = gql`
  mutation changeSensorCompany (
    $sensorId: Int!
    $companyId: Int!
  ) {
    changeSensorCompany (
      sensorId: $sensorId
      companyId: $companyId
    ) {
      success
      message
      sensor {
        ${sensor}
      }
    }
  } 
`;

export const GET_SENSOR_LOGS = gql`
  query getSensorLogs (
    $sensorId: String!
    $monitoringId: Int
  ) {
    getSensorLogs (
      sensorId: $sensorId
      monitoringId: $monitoringId
    ) {
      index
      measurement_timestamp
      temprature
    }
  } 
`;

export const GET_ACTIVE_SENSORS = gql`
  query getActiveSensors($userId: Int!) {
    getActiveSensors(userId: $userId) {
      ${sensor}
    }
  } 
`;

export const GET_SENSOR = gql`
  query getSensor(
      $id: Int!
    ) {
    getSensor(
        id: $id
    ) {
        sensor{
          ${sensor}
        }
        isReserved
        monitoringId
        monitoringName
    }
  } 
`;

export const GET_FILTERED_SENSORS = gql`
  query getFilteredSensorsResults(
      $key: String
      $keyword: String
      $mode: String
      $userId: Int
    ) {
      getFilteredSensorsResults(
        key: $key
        keyword: $keyword
        mode: $mode
        userId: $userId
    ) {
        ${sensor}
    } 
  } 
`;

export const UPDATE_SENSOR_INFO = gql`
  mutation updateSensorInfo(
      $sensorId: Int!
      $key: String
      $value: String
    ) {
      updateSensorInfo(
        sensorId: $sensorId
        key: $key
        value: $value
    ) {
        ${sensor}
    }
  } 
`;
