/**
 *
 * MonitoringWarenView component class
 *
 */

import React from "react";
import { Row, Card, Col, Form, Input, InputNumber, DatePicker, Button, Select } from "antd";
import moment from "moment";
import { Chart } from "react-charts";

import 'moment-timezone';

// styles
import "./monitoring-waren-view.css";

const data = [
  [[1, 10], [2, 10], [3, 10]],
  [[1, 10], [2, 10], [3, 10]],
  [[1, 10], [2, 10], [3, 10]]
];

const { RangePicker } = DatePicker;
const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
class MonitoringWarenView extends React.Component {

  state = {
    monitoringDates: [],
    min: '',
    max: '',
    email: '',
    comment: '',
    phone_number: '',
    started: '',
    ended: '',
  };

  componentWillReceiveProps(props) {
    if (this.props.monitoring !== props.monitoring) {
      this.setState({
        min: props.monitoring.min,
        max: props.monitoring.max,
        notification_to: props.monitoring.notification_to,
        comment: props.monitoring.comment,
        report_to: props.monitoring.report_to,
        started: props.monitoring.started,
        ended: props.monitoring.ended,
      });
    }
  };

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleInputNumberChange = (value, key) => {
    this.setState({ [key]: value });
  };

  handleSubmit = (e, key) => {
    this.props.updateMonitoringHandler(key, this.state[key]);
  };

  datesChangeHandler = (date, dateString) => {
    if (dateString.length === 1) {
      this.setState({ ended: dateString[0] });
    } else if (dateString.length === 2) {
      this.setState({
        started: dateString[0],
        ended: dateString[1],
      });
    }
  };

  handleClick = () => {
    this.props.handlerEndMonitoring();
  };

  onOk = (value) => {
    this.state.monitoringDates.push(value[0]._d);
    this.state.monitoringDates.push(value[1]._d);
    this.props.updateMonitoringDatesHandler("dates", this.state.monitoringDates);
  };

  onOkHandler = (value) => {
    this.state.monitoringDates.push(value._d);
    this.props.updateMonitoringDatesHandler("dates", this.state.monitoringDates);
  }

  startNowMonitoring = () => {
    this.props.handlerStartScheduledMonitoring();
  };

  render() {
    const { monitoring } = this.props;
    const { min, max, notification_to, comment, report_to } = this.state;

    return (
      <Row id="MonitoringWarenView">
        <h1>Monitoring: {monitoring && monitoring.monitoring_id}</h1>
        <Row>
          <Card className="mb-holder"
            title={`Monitoring - ${monitoring && monitoring.monitoring_id ? monitoring.monitoring_id : "N/A"} | Started: ${monitoring && monitoring.started ? moment(monitoring.started).tz("Europe/Berlin").format('DD.MM.YY HH:mm:ss [CET]') : "N/A"} | Finished: ${monitoring && monitoring.ended ? moment(monitoring.ended).tz("Europe/Berlin").format('DD.MM.YY HH:mm:ss [CET]') : "N/A"}`}
          >
            {monitoring && monitoring.started && !monitoring.ended && (
              <Row type="flex" align="middle" justify="center">
                <Button
                  className="end-monitoring-button"
                  onClick={this.handleClick}
                >
                  Stop Monitoring
                </Button>
              </Row>
            )}
            {monitoring && !monitoring.started && !monitoring.ended && (
              <Row type="flex" align="middle" justify="center">
                <Button
                  className="start-button"
                  onClick={this.startNowMonitoring}
                >
                  Start Monitoring
                </Button>
              </Row>
            )}

            <br/>
            <Row gutter={25} className="mb-card" type="flex">
              <Col lg={12}>
                <Card>
                  <Row>
                    <Col lg={4} className="label">
                      <label> Min ℃ </label>
                    </Col>
                    <Col lg={20}>
                      <InputNumber
                        value={min}
                        disabled={(monitoring.ended && new Date(monitoring.ended && monitoring.ended) < new Date()) ? true : false}
                        className="input-number"
                        onChange={(value) => this.handleInputNumberChange(value, 'min')}
                        onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'min') : null}
                        onBlur={(e) => this.handleSubmit(e, 'min')}
                      />
                    </Col>
                  </Row>
                  <Row className="row-container">
                    <Col lg={4} className="label">
                      <label> Max ℃ </label>
                    </Col>
                    <Col lg={20}>
                      <InputNumber
                        value={max}
                        disabled={(monitoring.ended && new Date(monitoring.ended && monitoring.ended) < new Date()) ? true : false}
                        className="input-number"
                        onChange={(value) => this.handleInputNumberChange(value, 'max')}
                        onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'max') : null}
                        onBlur={(e) => this.handleSubmit(e, 'max')}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <Row>
                    <Col lg={4} className="label">
                      <label>Customer</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                        <Input value={monitoring && monitoring.customer && monitoring.customer.name} disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="label">
                      <label>Description</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                      <Input
                        value={comment}
                        disabled={(monitoring.ended && new Date(monitoring.ended && monitoring.ended) < new Date()) ? true : false}
                        onChange={(e) => this.handleChange(e, 'comment')}
                        onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'comment') : null}
                        onBlur={(e) => this.handleSubmit(e, 'comment')}
                      />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={25} className="mb-card" type="flex">
              <Col lg={12} >
                <Card className="sen-loc-fields-card">
                  <Row>
                    <Col lg={4} className="label">
                      <label>Sensor</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                        <Input value={monitoring && monitoring.sensor && monitoring.sensor.name} disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="label">
                      <label>Position</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                        <Input disabled={true} value={monitoring.sensor_position} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="status-card">
                  <Row>
                    <Col lg={24}>
                      <Row className="started-ended-date-container">
                        <Col lg={4}>
                          <label className="text-label">Started</label>
                        </Col>
                        <Col lg={20}>
                          <Input value={monitoring && monitoring.started && monitoring.started} disabled={true} />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <label className="text-label">Ended</label>
                        </Col>
                        <Col lg={20}>
                          <Input value={monitoring && monitoring.ended && monitoring.ended} disabled={true} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="row-label">
                    <Col lg={4} className="label">
                      <label>Status</label>
                    </Col>
                    <Col lg={20} className="label">
                      <label className="label-color">{monitoring && monitoring.is_crtical === true ? <p className="critical">Critical</p> : <p className="safe">OK</p>}</label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={25} className="mb-card" type="flex">
              <Col lg={12}>
                <Card className="email-sms-fields-row">
                  <Row>
                    <Col lg={5} className="label">
                      <label>Notification To</label>
                    </Col>
                    <Col lg={19}>
                      <Input
                        value={notification_to}
                        disabled={(monitoring.ended && new Date(monitoring.ended && monitoring.ended) < new Date()) ? true : false}
                        onChange={(e) => this.handleChange(e, 'notification_to')}
                        onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'notification_to') : null}
                        onBlur={(e) => this.handleSubmit(e, 'notification_to')}
                      />
                    </Col>
                  </Row>
                  <Row className="row-container">
                    <Col lg={5} className="label">
                      <label>Report To</label>
                    </Col>
                    <Col lg={19}>
                      <Input
                        value={report_to}
                        disabled={(monitoring.ended && new Date(monitoring.ended && monitoring.ended) < new Date()) ? true : false}
                        onChange={(e) => this.handleChange(e, 'report_to')}
                        onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'report_to') : null}
                        onBlur={(e) => this.handleSubmit(e, 'report_to')}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="temperature-card">
                  <Row>
                    <Col lg={4} className="label">
                      <label>Last Update</label>
                    </Col>
                    <Col lg={20} className="label">
                      <label className="ant-input ant-input-disabled">{monitoring && monitoring.updatedAt ? moment(monitoring.updatedAt).tz("Europe/Berlin").format("DD.MM.YY HH:mm [CET]") : ''}</label>
                    </Col>
                  </Row>
                  <Row className="row-label">
                    <Col lg={4} className="label">
                      <label>Temperature</label>
                    </Col>
                    <Col lg={20} className="label">
                      <label className="ant-input ant-input-disabled">{monitoring && monitoring.sensor ? `${monitoring.sensor.last_temprature} °C` : ''}</label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            {monitoring && ((monitoring.comment) || (monitoring.sensor_pos_url)) && (
              <Row>
                <Card>
                  {monitoring && monitoring.comment && (
                    <Col lg={24}>
                      <label><b>Comment</b></label><p>{monitoring.comment}</p>
                    </Col>
                  )}

                  {monitoring && monitoring.sensor_pos_url && (
                    <Col lg={24}>
                      <img src={monitoring.sensor_pos_url} className="sensor_img" />
                    </Col>
                  )}
                </Card>
              </Row>
            )}
          </Card>
        </Row>
        <Row className="monitoring-report-container">
          <Card
            title={'Monitoring Report'}
          >
            <Row>
              <div
                style={{
                  width: "100%",
                  height: "300px"
                }}
              >
                <Chart
                  data={data}
                  axes={[
                    { primary: true, type: "linear", position: "bottom" },
                    { type: "linear", position: "left" }
                  ]}
                />
              </div>
            </Row>
            <Row className="display-flex report-buttons-container" gutter={25}>
              {monitoring && monitoring.ended &&
                <Col lg={8}>
                  <Button className="report-button" onClick={this.props.monthlypdfReport}>
                    Download PDF Report
                  </Button>
                </Col>
              }
              <Col lg={monitoring && monitoring.ended ? 8 : 16}>
                <Button className="report-button" onClick={this.props.downloadCSV}>
                  {'Download data as CSV file'}
                </Button>
              </Col>
            </Row>
          </Card>
        </Row>
      </Row>
    );
  }
}

MonitoringWarenView.propTypes = {};

export default Form.create()(MonitoringWarenView);
