import React, { Component } from 'react';
import moment from 'moment';
import Random from '@mobylogix/node-random';
import _ from 'lodash';
import FineUploaderS3 from 'fine-uploader-wrappers/s3';
import { connect } from 'react-redux';
import ImageCompressor from 'image-compressor.js';

export default function (WrappedComponent) {
  class WithUploaderHOC extends Component {

    constructor(props) {
      super(props);
      this.state = {
        submittedFiles: [],
        uploadedFile: null,
        uploading: false,
        needToRemoveIndex: -1,
        isPhotoRemoved: false,
      };
    }

    initialize() {
      let self = this;
      let index = self.props.index;
      const props = {
        options: {
          chunking: { enabled: false },
          deleteFile: { enabled: false },
          request: {
            endpoint: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com`,
          },
          credentials: {
            accessKey: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            expiration: moment().add(1, 'hour').toDate(),
          },
          objectProperties: {
            acl: 'public-read',
            bucket: process.env.REACT_APP_S3_BUCKET,
            region: process.env.REACT_APP_AWS_REGION,
            key: function (id) {
              const submittedFiles = self.state.submittedFiles;
              const url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/`;
              const key = `${Random.id()}`;
              const img = {
                url: url + key,
                id,
                index
              };
              submittedFiles.push(img);
              self.setState({ submittedFiles: [...submittedFiles] });
              return key;
            }
          },
          retry: { enableAuto: true, maxAutoAttempts: 1 },
          callbacks: {
            onCancel: function (id, name) {
              self.setState({ submittedFiles: [], uploading: false });
            },
            onComplete: function (id, name, res) {
              const uploadedFile = _.find(self.state.submittedFiles, { id });
              self.setState({
                uploadedFile: { ...uploadedFile, name },
                submittedFiles: [],
                uploading: false,
              });
            },
            onStatusChange: function (id, oldStatus, newStatus) {
              if (isFileGone(newStatus)) {
                const submittedFiles = [...self.state.submittedFiles];
                const indexToRemove = submittedFiles.indexOf(id);

                submittedFiles.splice(indexToRemove, 1);
                self.setState({ submittedFiles });
              }
            }
          }
        }
      };
      return new FineUploaderS3(props);
    }

    uploadImage = (file) => {
      const uploader = this.initialize();
      if (file) {
        new ImageCompressor(file, {
          quality: .6,
          success(result) {
            uploader.methods.addFiles([result]);
          }, error(e) {
            console.log(e.message);
          },
        });
        this.setState({ uploading: true, isPhotoRemoved: false });
      }
    }

    uploadFile = (file) => {
      const uploader = this.initialize();
      if (file) {
        uploader.methods.addFiles([file]);
        this.setState({ uploading: true, isPhotoRemoved: false });
      }
    }

    getFiles() {
      const { photos = [] } = this.props;
      const { uploadedFile } = this.state;
      if (uploadedFile === null) {
        return { updated: false, photos };
      }
      let { index, url } = uploadedFile;
      let p = [...photos];
      p[index] = url;
      return { updated: true, photos: p };
    }

    render() {
      let files = this.getFiles();
      let { isPhotoRemoved } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          uploadFile={this.uploadFile}
          uploadImage={this.uploadImage}
          uploadedFile={this.state.uploadedFile}
          uploading={this.state.uploading}
          files={files}
          isPhotoRemoved={isPhotoRemoved}
          needToRemoveIndex={this.state.needToRemoveIndex}
        />
      );
    }
  };

  return WithUploaderHOC;
}

const isFileGone = status => {
  return [
    'canceled',
    'upload failed',
    'deleted',
  ].indexOf(status) >= 0;
};
