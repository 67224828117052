/**
 *
 * AddUserCard component class
 *
 */
// Packages
import React from "react";
import { Row, Divider, Form, Icon, Card, Input, Button, Select } from "antd";

// styles
import "./add-customer-card.css";

const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
class AddCustomerCard extends React.Component {
  state = {
    company: '',
  };

  componentDidMount() {
    const { user, match: { params } } = this.props;
    if (params.id) {
      this.setValues(user);
    }
  };

  componentWillReceiveProps(props) {
    this.setCustomer(props);
  };

  setCustomer = (props) => {
    const { customer, match: { params } } = props;
    if (params.id && props.customer !== this.props.customer) {
      this.setValues(customer);
    }
  }

  setValues = (customer) => {
    const { form } = this.props;
    let data = {};
    if (customer) {
      data['name'] = customer.name;
      data['email'] = customer.email;
      data['monitoringType'] = customer.monitoring_type;
      data['minTemp'] = customer.min_temp;
      data['maxTemp'] = customer.max_temp;
      data['companyId'] = customer.company_id;
    }
    form.setFieldsValue(data);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.reduxHandler(values);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { companies } = this.props;

    return (
      <Row id="AddCustomerCard">
        <Card>
          <h2>Customer</h2>
          <Divider />
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Customer Name is required!' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="name" placeholder="Customer Name" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Email is required!' }],
              })(
                <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Email" />
              )}
            </Form.Item>
            <div className="roll-holder">
              <Icon className="roll-icon" type="user" />
              <Form.Item>
                {getFieldDecorator('monitoringType', {
                  rules: [{
                    required: true, message: 'Monitoring Type is required!',
                  }]
                })(
                  <Select placeholder="Select Monitoring Type" className="role-dropdown">
                    <Option value="Room">Room</Option>
                    <Option value="Truck">Truck</Option>
                    <Option value="Product">Product</Option>
                    <Option value="Pallet">Pallet</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <Form.Item>
              {getFieldDecorator('minTemp', {
                rules: [{
                  required: true, message: 'Min Temp is required!',
                }]
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Default Minimum Temprature" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('maxTemp', {
                rules: [{
                  required: true, message: 'Max Temp is required!',
                }]
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Default Maximum Temprature" />
              )}
            </Form.Item>
            <div className="roll-holder">
              <Icon className="roll-icon" type="bank" />
              <Form.Item>
                {getFieldDecorator('companyId', {
                  rules: [{
                    required: true, message: 'Company is required!',
                  }]
                })(
                  <Select placeholder="Select Company" className="company-dropdown">
                    {companies && companies.map((company) => (
                      <Option key={company.id} value={company.id}>{company.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="add-form-button">
                {this.props.match.params.id ? 'Update Cusstomer' : 'Add Customer'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  }
}

AddCustomerCard.propTypes = {};

export default Form.create()(AddCustomerCard);
