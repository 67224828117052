/**
 *
 * StartMonitoring component class
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Select, Input, Button, message, Spin, Icon, Checkbox } from 'antd';
import $ from "jquery";
import { Table } from 'reactstrap';
import moment from "moment";
import _ from "lodash";

// Components
import EmptyData from "../EmptyData";

// utils
import { ASSET_TYPES } from '../../utils/assetTypes';
import { COMMENTS_REGEX, SENSOR_DESCRIPTION_REGEX } from '../../utils/regex';

// styles
import "./start-monitoring.css";

const { TextArea } = Input;
const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
class StartMonitoring extends React.PureComponent {

  state = {
    isAsset: undefined,
    is_scheduled: false,
    data: undefined,
    next: false
  }

  handleCustomer = (value) => {
    if (!value) return message.error("Customer is required!");

    const { customers, form } = this.props;

    const customer = customers.find(c => c.id === value);

    if (!customer) return;

    form.setFieldsValue({ "min": customer.min_temp, "max": customer.max_temp, "monitoringType": customer.monitoring_type, "notificationTo": customer.email, "reportTo": customer.email });

    this.setState({ isAsset: ["Room", "Truck"].includes(customer.monitoring_type) });
  }

  handleMonitoringType = (value) => {
    if (!value) return message.error("Monitoring Type is required!");

    this.setState({ isAsset: ["Room", "Truck"].includes(value) });
  }

  handleSubmit = e => {
    e.preventDefault();

    const { location, form } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const data = {
          customer_id: values.customerId,
          min: parseFloat(values.min),
          max: parseFloat(values.max),
          notification_to: values.notificationTo,
          report_to: values.reportTo,
          monitoring_type: values.monitoringType,
          monitoring_id: values.monitoringId ? values.monitoringId : null,
          container_id: values.containerId ? values.containerId : null,
          container_name: values.containerName ? values.containerName : null,
          carrier: values.carrier ? values.carrier : null,
          delivery_note_no: values.deliveryNoteNo ? values.deliveryNoteNo : null,
          delivery_tour: values.deliveryTour ? values.deliveryTour : null,
          delivery_id: values.deliveryId ? values.deliveryId : null,
          delivery_content: values.deliveryContent ? values.deliveryContent : null,
          delivery_comment: values.deliveryComment ? values.deliveryComment : null,
          comment: values.comment ? values.comment : null,
          sensor_position: values.sensor_position ? values.sensor_position : null,
          sensor_pos_url: this.props.uploadedFile ? this.props.uploadedFile.url : null,
          is_scheduled: this.state.is_scheduled,
          created_by: localStorage.userId
        };

        if (location && location.search !== '') {
          const parts = location.search.split("=");
          const sensorId = parts[parts.length - 1];
          data['sensor_id'] = parseInt(sensorId);
          this.props.reduxHandler(data);
        } else {
          this.setState({ next: true, data });
        }
      }
    });
  }

  onSensorSelect = (sensorId) => {
    let { data } = this.state;
    data['sensor_id'] = sensorId;
    this.props.reduxHandler(data);
  };

  handleFileUpload = () => {
    const file = document.getElementById('image').files[0];
    if (file) {
      this.setState({ file: file.name });
      this.props.imageUploader(file);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { customers, uploading, uploadedFile, activeSensors } = this.props;
    const { isAsset, file, next } = this.state;

    if (next) {
      return (
        <div id="StartMonitoring">
          <h3>Start Monitoring</h3>
          <SensorStep
            activeSensors={activeSensors}
            onSensorSelect={this.onSensorSelect}
          />
        </div>
      );
    }

    return (
      <div id="StartMonitoring">
        <h3>Start Monitoring</h3>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Customer</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>
                {getFieldDecorator('customerId', {
                  initialValue: null
                })(
                  <Select
                    onChange={this.handleCustomer}
                  >
                    <Option value={null}>Select Customer</Option>
                    {customers && customers.map((customer, i) => (
                      <Option key={i} value={customer.id}>{customer.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Min Limit (℃)</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>
                {getFieldDecorator('min')(
                  <Input 
                    placeholder={"Minimum Temprature Limit"}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Max Limit (℃)</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>
                {getFieldDecorator('max')(
                  <Input 
                    placeholder={"Maximum Temprature Limit"}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Notification To</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>
                {getFieldDecorator('notificationTo')(
                  <Input 
                    placeholder={"Notification Email"}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Report To</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>
                {getFieldDecorator('reportTo')(
                  <Input 
                    placeholder={"Report Email"}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Monitoring Type</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>
                {getFieldDecorator('monitoringType', {
                  initialValue: null
                })(
                  <Select
                    onChange={this.handleMonitoringType}
                  >
                    <Option value={null}>Select Monitoring Type</Option>
                    {ASSET_TYPES && ASSET_TYPES.map((assetType, i) => (
                      <Option key={i} value={assetType}>{assetType}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Monitoring ID</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>
                {getFieldDecorator('monitoringId')(
                  <Input placeholder="Monitoring ID" />
                )}
              </Form.Item>
            </Col>
          </Row>

          {/* Assets  */}
          {isAsset === true &&
            <div>
              <hr />
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Container ID</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('containerId')(
                      <Input placeholder="Container ID" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Container Name</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('containerName')(
                      <Input placeholder="Container Name" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Carrier</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('carrier')(
                      <Input placeholder="Carrier" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <hr />
            </div>
          }

          {/* Delivery  */}
          {isAsset === false &&
            <div>
              <hr />
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Delivery Note #</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('deliveryNoteNo')(
                      <Input placeholder="Delivery Note #" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Delivery Tour</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('deliveryTour')(
                      <Input placeholder="Delivery Tour" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Delivery ID</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('deliveryId')(
                      <Input placeholder="Delivery ID" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Delivery Content</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('deliveryContent')(
                      <Input placeholder="Delivery Content" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <h5 className="label">Delivery Comment</h5>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item>
                    {getFieldDecorator('deliveryComment')(
                      <Input placeholder="Delivery Comment" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <hr />
            </div>
          }

          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Comments</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>              
                {getFieldDecorator('comment')(
                  <TextArea id="comments" placeholder="Comments" rows={4}></TextArea>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Place sensor</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>              
                {getFieldDecorator('sensor_position')(
                  <TextArea placeholder="Description of sensor" className="msg-box" autosize={{ minRows: 2, maxRows: 6 }} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <h5 className="label">Picture of sensor position</h5>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item>              
                {getFieldDecorator('sensor_picture')(
                  <Button className="photo-btn" onClick={() => $('#image').click()}>
                    <Input
                      id="image"
                      type="file"
                      onChange={() => this.handleFileUpload()}
                      hidden
                      accept="image/png, image/jpeg"
                    />
                    <Icon type="upload" /> Click to Upload
                  </Button>
                )}
              </Form.Item>
              {file && uploadedFile && uploadedFile.url &&
                <Row className="webcam">
                  <img src={uploadedFile.url} />
                </Row>
              }
              <Row className="uploading-image" type="flex" justify="center" align="middle">
                {uploading && (
                  <Spin className="file-loader" />
                )}
                {file && !uploading && (
                  <p><b>{file}</b></p>
                )}
              </Row>
            </Col>
          </Row>

          <hr />

          <Row className="footer-btns">
            <Button
              htmlType="submit"
              className="steps-btn"
              disabled={uploading}
            >
              Start Now
            </Button>
            <Button
              htmlType="submit"
              className="steps-btn"
              disabled={uploading}
              onClick={() => this.setState({ is_scheduled: true })}
            >
              Schedule
            </Button>
          </Row>
        </Form>
      </div>
    );
  }
}

StartMonitoring.propTypes = {};

export default Form.create()(StartMonitoring);

const SensorStep = ({ activeSensors, onSensorSelect, times = ['last_update', 'power_on_date'] }) => {
  return (
    <Row>
      {activeSensors && activeSensors.length === 0 ? (
        <EmptyData />
      ) : (
          <Table responsive>
            <thead>
              <tr>
                {_.map(['Sensor', 'Last Update', 'Power on Date', 'Last Battery [mV]', 'Mac'], (heading, i) => (
                  <th key={i}>{heading}</th>
                ))
                }
              </tr>
            </thead>
            {activeSensors && activeSensors &&
              <tbody>
                {_.map(activeSensors && activeSensors, (record) => (
                  <tr key={record.id} onClick={() => onSensorSelect(record.id)}>
                    {['name', 'last_update', 'power_on_date', 'last_battery', 'mac'].map((key, i) => (
                      <td key={i}>
                        {key === 'is_active' ? record.is_active === true ? <Icon type="check" /> : <Icon type="stop" /> : null}
                        {times.includes(key) && moment(record[key]).tz("Europe/Berlin").format("DD-MM-YY HH:mm")}
                        {key !== 'is_active' && !times.includes(key) && record[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            }
          </Table>
        )}
    </Row>
  )
};