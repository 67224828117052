/**
 *
 * SensoInfoView component class
 *
 */

import React from "react";
import { Row, Col, Card, Input, Button, DatePicker, Tooltip, message, Select } from "antd";
import { Chart } from "react-charts";
import moment from "moment";

import { CSV_REPORT } from "../../reports/csvReport"; 

// styles
import "./senso-info-view.css";

const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
class SensoInfoView extends React.Component {
  state = {
    log_interval: '',
    log_capacity: '',
    comments: '',
    next_sample: '',
  };

  componentWillReceiveProps(props) {
    if (this.props.sensor !== props.sensor) {
      this.setState({
        log_interval: props.sensor && props.sensor.log_interval ? props.sensor.log_interval : null,
        log_capacity: props.sensor && props.sensor.log_capacity ? props.sensor.log_capacity : null,
        comments: props.sensor && props.sensor.comments ? props.sensor.comments : null,
        next_sample: props.sensor && props.sensor.next_sample ? props.sensor.next_sample : null,
      });
    }
  };

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleSubmit = (e, key) => {
    this.props.sensorUpdateHandler(key, this.state[key]);
  };

  onOkHandler = (dateString) => {
    if(dateString > moment()) {
      this.setState({ next_sample: dateString });
      this.props.sensorUpdateHandler('next_sample', dateString);
    } else if(!dateString) {
      message.error('Next sample date cannot be null');
    }  else {
      message.error('Next sample date should be equal or greater than current date');
    }
  };

  downloadCSV = async () => {
    if (this.props.sensor) {
      await this.props.getSensorLogs(this.props.client, { sensorId: this.props.sensor.mac });
      CSV_REPORT(null, this.props.sensorLogs);
    }
  };

  changeCompany = async (value) => {
    try {
      const { client, changeSensorCompany, sensor } = this.props;
      await changeSensorCompany(client, { sensorId: sensor.id, companyId: value });
      message.success("Sensor company updated successfully!");
    } catch (err) {
      message.error(err.message);
    }
  }

  render() {
    const { sensor, isReserved, monitoringId, monitoringName, currentUser , companies, logsLoading } = this.props;
    const { log_interval, log_capacity, comments, next_sample } = this.state;

    if (!sensor) return null;

    return (
      <Row id="SensoInfoView">
        <h1>{sensor.name}</h1>
        <Row>
          <Card
            title={sensor.name}
          >
            <Row className="device-info-row" gutter={25}>
              <Col lg={6} md={24} sm={12} xs={24}>
                <Input
                  addonBefore={'Temperature'}
                  value={sensor.last_temprature}
                  disabled={true}
                />
              </Col>
              <Col lg={6} md={24} sm={12} xs={24}>
                <Input
                  addonBefore={'Last Battery'}
                  value={sensor.last_battery}
                  disabled={true}
                />
              </Col>
              <Col lg={6} md={24} sm={12} xs={24}>
                <Input
                  addonBefore={'Last Update'}
                  value={moment(sensor.last_update).tz("Europe/Berlin").format("DD.MM.YY HH:mm:ss [CET]")}
                  disabled={true}
                />
              </Col>
              <Col lg={6}>
                <Input
                  addonBefore={'Signal Strength'}
                  value={sensor.signal_strength ? sensor.signal_strength : 'N/A'}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row className="device-info-row device-info-container" gutter={25}>
              {/* <Col lg={8} className="field-with-prefix">
                <Tooltip placement="bottom" title={'Next Sample'}>
                  <label className="prefix">Next Sample</label>
                  <DatePicker
                    value={moment(next_sample)}
                    onChange={this.onOkHandler}
                    className="date-picker"
                  />
                </Tooltip>
              </Col> */}
            </Row>
          </Card>
        </Row>
        {/* <Row className="realtime">
          <Card
            title={'Realtime Chart'}
          >
            <div
              style={{
                width: "100%",
                height: "300px"
              }}
            >
              <Chart
                data={[
                  {
                    label: "Series 1",
                    data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
                  },
                  {
                    label: "Series 2",
                    data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
                  }
                ]}
                axes={[
                  { primary: true, type: "linear", position: "bottom" },
                  { type: "linear", position: "left" }
                ]}
              />
            </div>
          </Card>
        </Row> */}
        <Row className="start-monitoring">
          <Card
            title={'Start Monitoring'}
          >
            <Row>
              {isReserved ? (
                <Button
                  className="start-monitoring-button"
                  onClick={() => this.props.history.push(`/monitoring/${monitoringId}`)}
                >
                  {monitoringName ? `Go to Monitoring ${monitoringName}` : 'Go to Monitoring'}
                </Button>
              ) : (
                  <Button
                    className="start-monitoring-button"
                    onClick={() => this.props.history.push(`/start-monitoring?sensor_id=${sensor.id}`)}
                  >
                    Start Monitoring
                  </Button>
                )}
            </Row>
          </Card>
        </Row>
        {currentUser && currentUser.role === "Super Admin" &&
          <Row className="start-monitoring">
            <Card
              title={'Admin Section'}
            >
              <Row className="device-info-row device-info-container" gutter={25}>
                <Col lg={6} md={24} sm={12} xs={24}>
                  <Input
                    addonBefore={'MAC'}
                    value={sensor.mac}
                    disabled={true}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    addonBefore={'Created At'}
                    value={sensor.createdAt ? moment(sensor.createdAt).tz("Europe/Berlin").format("DD.MM.YY HH:mm:ss [CET]") : null}
                    disabled={true}
                  />
                </Col>
                <Col lg={6}>
                  <Select value={sensor && sensor.company && sensor.company.id ? sensor.company.id : null} onChange={this.changeCompany} style={{ width: '100%' }}>
                    {companies.map((company, i) => (
                      <Option key={i} value={company.id}>{company.name}</Option>
                    ))}
                  </Select>
                  {/* <Input
                    value={sensor.company && sensor.company.name ? sensor.company.name : null}
                    disabled={true}
                  /> */}
                </Col>
              </Row>
              <Row className="device-info-row device-info-container" gutter={25}>
                <Col lg={6}>
                  <Input
                    addonBefore={'Log Interval'}
                    value={log_interval && log_interval}
                    onChange={(e) => this.handleChange(e, 'log_interval')}
                    onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'log_interval') : null}
                    onBlur={(e) => this.handleSubmit(e, 'log_interval')}

                  />
                </Col>
                <Col lg={6}>
                  <Input
                    addonBefore={'Log Capacity'}
                    value={log_capacity && log_capacity}
                    onChange={(e) => this.handleChange(e, 'log_capacity')}
                    onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'log_capacity') : null}
                    onBlur={(e) => this.handleSubmit(e, 'log_capacity')}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    addonBefore={'Comments'}
                    value={comments}
                    onChange={(e) => this.handleChange(e, 'comments')}
                    onKeyUp={(e) => e.keyCode === 13 ? this.handleSubmit(e, 'comments') : null}
                    onBlur={(e) => this.handleSubmit(e, 'comments')}
                  />
                </Col>
              </Row>
            </Card>
          </Row>
        }
      </Row>
    );
  }
}

SensoInfoView.propTypes = {};

export default SensoInfoView;
