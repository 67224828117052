/**
 *
 * AboutCard component class
 *
 */
// Packages
import React from "react";
import { Row, Card } from "antd";
import PropTypes from "prop-types";

// styles
import "./about-card.css";

/* eslint-disable react/prefer-stateless-function */
class AboutCard extends React.Component {
  render() {
    const { currentUser } = this.props;
    return (
      <Row id="AboutCard">
        <Card title={'App Info'}>
          {currentUser && currentUser.company && currentUser.company.name && currentUser.company.name && (
            <Row>
              <h4>{currentUser.company.name}</h4>
            </Row>
          )}
          <Row>
            <p><b>User Role: </b>{currentUser && currentUser.role && currentUser.role}</p>
          </Row>
          <Row>
            <p><b>App Name: </b>{process.env.REACT_APP_NAME}</p>
          </Row>
          <Row>
            <p><b>Version: </b>{process.env.REACT_APP_VERSION}</p>
          </Row>
          <Row>
            <p><b>URL: </b>{process.env.REACT_APP_URL}</p>
          </Row>
        </Card>
      </Row>
    );
  }
}

AboutCard.propTypes = {};

export default AboutCard;
