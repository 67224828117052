import gql from "graphql-tag";


const sensor = `
  id
  name
  mac
  is_active
  last_battery
  last_temprature
  last_update
`;

const monitorings = `
  id
  customer_id
  customer {
    name
  }
  min
  max
  notification_to
  report_to
  monitoring_type
  monitoring_id
  container_id
  container_name
  carrier
  delivery_note_no
  delivery_tour
  delivery_id
  delivery_content
  delivery_comment
  comment
  sensor {
    ${sensor}
  }
  sensor_position
  sensor_pos_url
  sensor_id
  created_by {
    name
    email
  }
  started
  ended
  is_scheduled
  is_active
  is_crtical
  critical_at
  updatedAt
`;

export const GET_MONITORINGS_COUNT = gql`
  query getMonitoringsCount($userId: Int) {
    getMonitoringsCount(userId: $userId) {
      count
    }
  } 
`;

export const GET_MONITORINGS = gql`
  query getMonitorings (
    $pageNo: Int!
    $userId: Int
  ){
    getMonitorings (
      pageNo: $pageNo
      userId: $userId
    ) {
      ${monitorings}
    }
  } 
`;

export const GET_FILTERED_MONITORINGS = gql`
  query getFilteredMonitoringsResults(
    $keyword: String
    $isActive: String
    $isCritical: String
    $assetType: String
    $userId: Int
  ) {
    getFilteredMonitoringsResults(
      keyword: $keyword
      isActive: $isActive
      isCritical: $isCritical
      assetType: $assetType
      userId: $userId
    ) {
      monitorings {
        ${monitorings}
      }
      count
    }
  } 
`;

export const GET_MONITORING = gql`
  query getMonitoring($id: Int) {
    getMonitoring(id: $id) {
      ${monitorings}
    }
  } 
`;

export const END_MONITORING = gql`
  mutation endMonitoring($id: Int) {
    endMonitoring(id: $id) {
      success
      message
    }
  } 
`;

export const START_MONITORING = gql`
  mutation startMonitoring(
    $customer_id: Int
    $min: Float
    $max: Float
    $notification_to: String
    $report_to: String
    $monitoring_type: String
    $monitoring_id: String
    $container_id: String
    $container_name: String
    $carrier: String
    $delivery_note_no: String
    $delivery_tour: String
    $delivery_id: String
    $delivery_content: String
    $delivery_comment: String
    $comment: String
    $sensor_position: String
    $sensor_pos_url: String
    $is_scheduled: Boolean
    $sensor_id: Int
    $created_by: Int
  ) {
    startMonitoring(
      customer_id: $customer_id
      min: $min
      max: $max
      notification_to: $notification_to
      report_to: $report_to
      monitoring_type: $monitoring_type
      monitoring_id: $monitoring_id
      container_id: $container_id
      container_name: $container_name
      carrier: $carrier
      delivery_note_no: $delivery_note_no
      delivery_tour: $delivery_tour
      delivery_id: $delivery_id
      delivery_content: $delivery_content
      delivery_comment: $delivery_comment
      comment: $comment
      sensor_position: $sensor_position
      sensor_pos_url: $sensor_pos_url
      is_scheduled: $is_scheduled
      sensor_id: $sensor_id
      created_by: $created_by
    ) {
      success
      message
    }
  } 
`;

export const UPDATE_MONITORING_WAREN = gql`
  mutation updateMonitoringWaren(
    $id: Int
    $min: Int
    $max: Int
    $email: String
    $phone_number: String
  ) {
    updateMonitoringWaren(
      id: $id
      min: $min
      max: $max
      email: $email
      phone_number: $phone_number
    ) {
      ${monitorings}
    }
  } 
`;

export const GET_MONITORINGS_FILTERED_RESULTS = gql`
  query getFilteredMonitoringResults(
    $key: String
    $value: String
  ) {
    getFilteredMonitoringResults(
      key: $key
      value: $value
    ) {
      ${monitorings}
    }
  } 
`;

export const START_SCHEDULED_MONITORING = gql`
  mutation startScheduledMonitoring(
    $id: Int
  ) {
    startScheduledMonitoring(
      id: $id
    ) {
      ${monitorings}
    }
  } 
`;

export const GET_SELECT_MONITORINGS_FILTERED_RESULTS = gql`
  query getSelectFilteredMonitoringResults(
    $leftKey: String
    $centerKey: String
    $rightKey: String
  ) {
    getSelectFilteredMonitoringResults(
      leftKey: $leftKey
      centerKey: $centerKey
      rightKey: $rightKey
    ) {
      ${monitorings}
    }
  } 
`;

export const UPDATE_MONITORING_INFO = gql`
  mutation updateMonitoringInfo(
    $id: Int
    $key: String
    $value: String
  ) {
    updateMonitoringInfo(
      id: $id
      key: $key
      value: $value
    ) {
      ${monitorings}
    }
  } 
`;

export const UPDATE_MONITORING_DATES = gql`
  mutation updateMonitoringDates(
    $id: Int
    $key: String
    $dates: [String]
  ) {
    updateMonitoringDates(
      id: $id
      key: $key
      dates: $dates
    ) {
      ${monitorings}
    }
  } 
`;