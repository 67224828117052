/**
 * Columns filters data
 */

export const DASHBOARD_MONITORING_COLUMNS = [
  {
    title: 'Monitoring ID',
    dataIndex: 'monitoring_id',
  },
  {
    title: 'Monitoring Type',
    dataIndex: 'monitoring_type',
  },
  {
    title: 'Monitoring Details',
    dataIndex: 'comment',
  },
  {
    title: 'Min Temp (℃)',
    dataIndex: 'min',
  },
  {
    title: 'Max Temp (℃)',
    dataIndex: 'max',
  },
  {
    title: 'Sensor',
    dataIndex: 'sensor',
  },
  {
    title: 'Sensor Position',
    dataIndex: 'sensor_position',
  },
  {
    title: 'Date Started',
    dataIndex: 'started',
  },
  {
    title: 'Date Ended',
    dataIndex: 'ended',
  },
];

export const SENSORS_COLUMNS = [
  {
    title: 'Sensor',
    dataIndex: 'name',
  },
  {
    title: 'Company',
    dataIndex: 'company_name',
  },
  {
    title: 'Last Update',
    dataIndex: 'last_update',
  },
  {
    title: 'Power on Date',
    dataIndex: 'power_on_date',
  },
  {
    title: 'Last Battery [mV]',
    dataIndex: 'last_battery',
  },
  {
    title: 'Mac',
    dataIndex: 'mac'
  }
];

export const GATEWAYS_COLUMNS = [
  {
    title: 'Is Active',
    dataIndex: 'is_active',
  },
  {
    title: 'Gateway ID',
    dataIndex: 'Gateway_ID'
  },
  {
    title: 'Company Name',
    dataIndex: 'company',
  },
  {
    title: 'Last Battery',
    dataIndex: 'last_battery',
  },
  {
    title: 'Location',
    dataIndex: 'location',
  },
  {
    title: 'Location Id',
    dataIndex: 'location_id',
  },
  // {
  //   title: 'Location',
  //   dataIndex: 'last_location',
  // },
  {
    title: 'MAC',
    dataIndex: 'mac',
  },
  {
    title: 'Last Updated',
    dataIndex: 'updatedAt'
  }
];

