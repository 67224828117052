/**
 *
 * CreateDeliveryTaskView component class
 *
 */

// Packages
import React from "react";
import _ from "lodash";
import moment from "moment";
import { Row, Col, Divider, Tooltip, Popconfirm, Icon, Card, Form, Input, Table, Checkbox, InputNumber, Button, Select } from "antd";
import PropTypes from "prop-types";

// styles
import "./create-delivery-task-view.css";

// Utils 
import Column from "antd/lib/table/Column";
import { TASKS_DELIVERY_COLUMNS } from "../../utils/data";

const { Option } = Select;
let id = 0;

/* eslint-disable react/prefer-stateless-function */
class CreateDeliveryTaskView extends React.Component {
  state = {
    boxes: []
  }

  componentDidMount() {
    const task = this.props.task;
    const boxes = [];
    _.times(task && task.box_id ? task.box_id.length : 0, i => {
      boxes.push(i);
    });

    this.setState({ boxes });

    if (task) {
      if (this.props.match.params.id) {
        this.setValues(task);
      }
    }
  };

  componentWillReceiveProps(props) {
    this.setTask(props);
  };

  setTask = (props) => {
    const { task, match: { params } } = props;
    if (params.id && props.task !== this.props.task) {
      this.setValues(task);
    }
  };

  setValues = (task) => {
    const { form } = this.props;
    const keys = Object.keys(task);
    const values = Object.values(task);

    form.setFieldsValue(task);
    const boxKeys = ['box_id', 'box_description', 'monitorbox_status', 'sensor_id', 'limit_min', 'limit_max'];

    const noOFBoxes = task.box_id.length;

    for (let j = 0; j < keys.length; j++) {
      if (boxKeys.includes(keys[j])) {
        for (let k = 0; k < noOFBoxes; k++) {
          setTimeout(() => {
            form.setFieldsValue({ [`${keys[j]}[${k}]`]: values[j][k] });
          }, 1000);
        }
      }
    }
  };

  remove = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    if (keys.length === 1) {
      return;
    };

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.match.params.id) {
          const tasktId = this.props.match.params.id;
          this.props.updateReduxHandler(values, tasktId);
        } else {
          this.props.reduxHandler(values);
        }
        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { boxes } = this.state;

    getFieldDecorator('keys', { initialValue: boxes });
    const keys = this.props.form.getFieldValue('keys');

    const formItems = keys.map((k, index) => (
      <Form.Item
        required={false}
        key={`${k}-${index}`}
      >
        <Row className="add-box-container">
          <Card title="Add Box">
            <Form.Item
              label="Box ID"
            >
              {getFieldDecorator(`box_id[${k}]`, {
                rules: [{
                  required: true, message: 'Box ID is required!',
                }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              label="Box Description"
            >
              {getFieldDecorator(`box_description[${k}]`)(
                <Input />
              )}
            </Form.Item>
            <Form.Item>
              <label><b>Monitor Box: </b></label>
              {getFieldDecorator(`monitor_box[${k}]`, {
                valuePropName: 'checked',
                initialValue: true,
              })(
                <Checkbox>Yes</Checkbox>
              )}
            </Form.Item>

            <Form.Item
              label="Sensor (optional)"
            >
              {getFieldDecorator(`sensor[${k}]`)(
                <Select placeholder="Please select sensor" style={{ width: 120 }} onChange={this.handleChange}>
                  <Option value="Proximity Sensor">Proximity Sensor</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Limit Min"
            >
              {getFieldDecorator(`limit_min[${k}]`)(
                <InputNumber min={1} max={1000} />
              )}
            </Form.Item>
            <Form.Item
              label="Limit Max"
            >
              {getFieldDecorator(`limit_max[${k}]`)(
                <InputNumber min={1} max={1000} />
              )}
            </Form.Item>
          </Card>
        </Row>
        <Row type="flex" justify="center">
          {keys.length > 1 ? (
            <Tooltip title="Remove Task" placement={"bottom"}>
              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                disabled={keys.length === 1}
                onClick={() => this.remove(k)}
              />
            </Tooltip>
          ) : null}
        </Row>
      </Form.Item>
    ));

    return (
      <Row id="CreateDeliveryTaskView">
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Card title={this.props.match.params.id ? "Edit delivery monitoring " : "Create new delivery monitoring"}>
              <Form.Item
                label="Order ID"
              >
                {getFieldDecorator('order_id', {
                  rules: [{
                    required: true, message: 'Order ID is required!'
                  }],
                })(
                  <Input />
                )}
              </Form.Item>
            </Card>
          </Row>
          <Row className="address-container" gutter={25}>
            <Col lg={12}>
              <Card title="Pickup Address">
                <Form.Item
                  label="Name"
                >
                  {getFieldDecorator('pickup_name', {
                    rules: [{
                      required: true, message: 'Name is required!',
                    }],
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item
                  label="Address"
                >
                  {getFieldDecorator('pickup_address', {
                    rules: [{
                      required: true, message: 'Address is required!',
                    }],
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item
                  label="When to pick up?"
                >
                  {getFieldDecorator('pickup_time', {
                    rules: [{
                      required: true, message: 'Pick up time is required!',
                    }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Card>
            </Col>
            <Col lg={12} className="to-address-container">
              <Card title="To Address">
                <Form.Item
                  label="Name"
                >
                  {getFieldDecorator('receiver_name', {
                    rules: [{
                      required: true, message: 'Name is required!',
                    }],
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item
                  label="Address"
                >
                  {getFieldDecorator('receiver_address', {
                    rules: [{
                      required: true, message: 'Address is required!',
                    }],
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item
                  label="When delivery deliver?"
                >
                  {getFieldDecorator('receiver_time', {
                    rules: [{
                      required: true, message: 'Pick up time is required!',
                    }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card title="Delivery Boxes(use the form below to add new boxes for this delivery)">
              {/* <Table dataSource={this.props.boxes}>
                <Column
                  title="Box ID"
                  dataIndex="box_id"
                  key="box_id"
                />
                <Column
                  title="Box Description"
                  dataIndex="box_description"
                  key="box_description"
                />
                <Column
                  title="Monitor Item"
                  dataIndex="monitorbox_status"
                  key="monitorbox_status"
                />
                <Column
                  title="Limit Min"
                  dataIndex="limit_min"
                  key="limit_min"
                />
                <Column
                  title="Limit Max"
                  dataIndex="limit_max"
                  key="limit_max"
                />
                <Column
                  title="Actions"
                  key="actions"
                  render={(record) => (
                    <span>
                      <Tooltip placement="bottom" title={"Edit Box"}>
                        <Icon
                          type="edit"
                        />
                      </Tooltip>
                      <Divider type="vertical" />
                      <Tooltip placement="bottom" title={"Delete Box"}>
                        <Popconfirm
                          title="Are you sure delete this box?"
                          onConfirm={() => this.props.deleteBox(record.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Icon
                            type="delete"
                          />
                        </Popconfirm>
                      </Tooltip>
                    </span>
                  )}
                />
              </Table> */}
            </Card>
          </Row>
          {formItems}
          <Card className="add-item-card">
            <Form.Item>
              <Button
                className="add-item-button"
                onClick={this.add}
              >
                <Icon type="plus" /> Add Item
              </Button>
            </Form.Item>
          </Card>
          <Row className="schedule-container">
            <Card title="Schedule">
              <Form.Item
                label="Delivered By"
              >
                {getFieldDecorator('delivered_by', {
                  rules: [{
                    required: true, message: 'Driver is required!',
                  }]
                })(
                  <Select placeholder="Please select driver" style={{ width: 120 }} onChange={this.handleChange}>
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>Disabled</Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                )}
              </Form.Item>
            </Card>
          </Row>
          <Card className="add-task-container">
            <Row type="flex" justify="center" align="middle">
              <Button htmlType="submit" className="add-task-button">
                {this.props.match.params.id ? "Update Task" : "Add Task"}
              </Button>
            </Row>
          </Card>
        </Form>
      </Row>
    );
  }
}

CreateDeliveryTaskView.propTypes = {};

export default Form.create()(CreateDeliveryTaskView);
