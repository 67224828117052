/**
 *
 * MyHeader stateless component
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Layout, Icon, Menu, Row, Col, Tooltip } from 'antd';

// Components
import ChangePassword from "../ChangePassword";

// styles
import "./my-header.css";

// Utils -> roles
import { ROLES } from "../../utils/roles";

const { Header } = Layout;
const SubMenu = Menu.SubMenu;

class MyHeader extends React.Component {

  state = {
    current: 'monitorings',
    visible: false,
  }

  options = [
    { menuItem: true, key: 'monitorings', title: 'Monitorings', route: '/monitorings' },
    { menuItem: true, key: 'sensors', title: 'Sensors', route: '/sensors' },
    { menuItem: true, key: 'gateway', title: 'Gateways', route: '/gateways' },
    // { menuItem: true, key: 'products', title: 'Products', route: '/products' },
    // { menuItem: false, key: 'delivery', title: 'Delivery', menuItemGroup: [{ subitem: 'Mobile Delivery Tasks', route: '/delivery-tasks' }, { subitem: 'Create Mobile Delivery Task', route: '/delivery-task/create' }] },
    // { menuItem: false, key: 'tools', title: 'Tools', menuItemGroup: [{ subitem: 'About app versions', route: '/monitorings'}]},
    // { menuItem: false, key: 'management', title: 'Management', menuItemGroup: [{ subitem:'Devices (change owner)', route: '/devices'}, { subitem: 'Users', route: '/users'}, { subitem: 'Companies', route: '/companies'}]},
    { menuItem: false, key: 'management', title: 'Management', menuItemGroup: [{ subitem: 'Users', route: '/users' }, { subitem: 'Customers', route: '/customers' }, { subitem: 'Companies', route: '/companies' }, { subitem: 'Locations', route: '/locations' }] },
    { menuItem: false, className: 'sample', key: 'delivery', title: 'User', menuItemGroup: [{ subitem: localStorage.getItem('userEmail'), route: '' }, { subitem: 'Change Password', route: '' }, { subitem: 'Logout', route: '/' }] },
  ];

  otherUserOptions = [
    { menuItem: true, key: 'monitorings', title: 'Monitorings', route: '/monitorings' },
    { menuItem: true, key: 'sensors', title: 'Sensors', route: '/sensors' },
    { menuItem: false, className: 'sample', key: 'delivery', title: 'User', menuItemGroup: [{ subitem: localStorage.getItem('userEmail'), route: '' }, { subitem: 'Change Password', route: '' }, { subitem: 'Logout', route: '/' }] },
  ];

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };

  handleModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    const { isCurrentGateway, history, currentUser, logout } = this.props;
    const path = history.location.pathname;
    const menus = currentUser && currentUser.role && [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(currentUser.role) ? this.options : this.otherUserOptions;

    return (
      <Header id="MyHeader">
        <Row gutter={25}>
          <Col className="logo-holder" lg={4} md={5} xs={10}>
            <div className="logo-inner">
              <img
                src={require('../../assets/images/tsenso_Logo-01_klein.png')}
                onClick={() => history.push('/monitorings')}
                className="tsenso-logo"
              />
              <Tooltip placement="bottom" title={'About App'}>
                <Icon type="wifi" className="wifi-logo" onClick={() => history.push('/about')} />
              </Tooltip>
              <p className="wifi-details">Ver. {process.env.REACT_APP_VERSION}</p>
            </div>
          </Col>
          {isCurrentGateway &&
            <Col lg={20} md={19} xs={14} className="main-nav-holder">
              <Menu
                selectedKeys={[this.state.current]}
                mode="horizontal"
                className="main-navigation"
              >
                {menus.map((option, key) => (
                  option.menuItem ? (
                    <Menu.Item
                      key={key}
                      onClick={() => history.push(option.route)}
                    >
                      <span className={path === option.route ? "active-route" : ""}>{option.title}</span>
                    </Menu.Item>
                  ) : (
                      <SubMenu key={key} title={<span className="submenu-title-wrapper">{option.icon}{option.title}<Icon type="caret-down" /></span>}>
                        {option.menuItemGroup.map((item, key) => (
                          <Menu.Item
                            key={key}
                            onClick={() => {
                              history.push(option.menuItemGroup !== 'Logout' &&
                                item.route
                              )
                              if (item.subitem === 'Logout') {
                                localStorage.removeItem('userId');
                                history.push('/');
                              }
                              if(item.subitem === 'Change Password') {
                                this.handleModal();
                              }
                            }}
                          >
                            <span className={path === item.route ? "active-route" : ""}>
                              {item.subitem}
                            </span>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    )
                ))}
              </Menu>
              <Menu
                selectedKeys={[this.state.current]}
                mode="horizontal"
                className="user-account"
              >
                <SubMenu title={<span className="logout submenu-title-wrapper"> <Icon type="user" /> </span>}>
                  <Menu.Item className="user-name">
                    {localStorage.getItem('userEmail')}
                  </Menu.Item>

                  <Menu.Item
                    onClick={this.handleModal}
                  >
                    <a><Icon type="lock" />{'Change Password'}</a>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => logout()}
                  >
                    <a><Icon type="logout" />{'Logout'}</a>
                  </Menu.Item>
                </SubMenu>
              </Menu>
              <ChangePassword
                changePasswordHandler={this.props.changePasswordHandler}
                visible={this.state.visible}
                handleModal={this.handleModal.bind(this)}
              />
            </Col>
          }
        </Row>
      </Header>
    );
  }
}

MyHeader.propTypes = {};

export default MyHeader;