import downloadCsv from 'download-csv';
import moment from "moment";
import { message } from 'antd';

export const CSV_REPORT = (monitoring, logs) => {
  if (!logs) return message.error("Your request cannot be processed at this time!");
  console.log("logs....", logs);

  let fileteredMonitorings = 'MAC,Timestamp,Index,Temprature\n';
  for (let i = 0; i < logs.length; i++) {
    const log = logs[i];
    fileteredMonitorings += monitoring && monitoring.sensor && monitoring.sensor.mac ? monitoring.sensor.mac : '';
    fileteredMonitorings += ',';
    fileteredMonitorings += log ? moment(parseInt(log.measurement_timestamp)).tz("Europe/Berlin").format("DD.MM.YY HH:mm:ss [CET]") : '';
    fileteredMonitorings += ',';
    fileteredMonitorings += log ? `${log.index}` : '';
    fileteredMonitorings += ',';
    fileteredMonitorings += log ? `${log.temprature} °C` : '';
    fileteredMonitorings += '\n';
  }

  const columns = {
    mac: 'mac',
    timestamp: 'timestamp',
    index: 'index',
    temprature: 'temprature',
  }

  const date = new Date();
  downloadCsv(fileteredMonitorings, columns, `${moment(date).tz("Europe/Berlin").format('YYYY-MM-DD-HHmm')}_tsenso-monitoring.csv`);
};
