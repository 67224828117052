/**
 *
 * MonitoringWizardView component class
 *
 */
// Packages
import React from "react";
import $ from "jquery";
import { DatePicker, Row, Card, Form, Spin, Alert, Select, Col, Input, Button, Icon, Tooltip, Steps, message, Checkbox, Divider, Upload } from "antd";
import PropTypes from "prop-types";
import Webcam from "react-webcam";
import { Table } from 'reactstrap';
import moment from "moment";
import _ from "lodash";

// Components
import EmptyData from "../EmptyData";

// styles
import "./monitoring-wizard-view.css";

// utils -> Asset Types
import { ASSET_TYPES } from "../../utils/assetTypes";

//utils -> Regex
import { EMAIL_REGEX, COMMENTS_REGEX, SENSOR_DESCRIPTION_REGEX, NUMBER_REGEX } from "../../utils/regex";

// utils -> helpers
import { dataURItoBlob } from '../../utils/helpers';

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;
const { RangePicker } = DatePicker;

const arr = new Array(2);

/* eslint-disable react/prefer-stateless-function */
class MonitoringWizardView extends React.Component {

  state = {
    current: 0,
    assetType: '',
    notify: '',
    sensor: '',
    stepperPage: 0,
    isChecked: true,
    takePhotoHeight: 100,
    isDescriptionrequired: false,
    file: null,
    uploadSensorPhoto: true,
    takeSensorPhoto: true,
    isRangePicker: false,
    columnHeadings: ['Sensor', 'Last Update', 'Power on Date', 'Last Battery [mV]', 'Mac'],
    columnKeys: ['name', 'last_update', 'power_on_date', 'last_battery', 'mac'],
  };

  setRef = webcam => {
      this.webcam = webcam;
  };

  capture = () => {
    this.setState({ takePhotoHeight: 480, uploadSensorPhoto: false });
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ file: 'image.jpg' });
    this.props.uploadFile(dataURItoBlob(imageSrc));
  };

  next = () => {
    const { assetType, sensor } = this.state;
    let sensor_id;

    if (!this.props.id) {
      sensor_id = sensor;
    }

    const min = document.getElementById('min').value;
    const max = document.getElementById('max').value;
    const name = document.getElementById('name').value;
    const comments = document.getElementById('comments').value;
    const assetId = document.getElementById('asset_id').value;
    const assetName = document.getElementById('asset_name').value;

    let values = {
      sensor_id: sensor_id,
      asset_type: assetType,
      min: min,
      max: max,
      name: name,
      comments: comments,
      asset_id: assetId,
      asset_name: assetName,
    };

    this.props.form.validateFields((err) => {
      if (!err) {
        const current = this.state.current + 1;
        this.setState({ current: current });
        this.props.firstStepReduxHandler(values);
      }
    });
  };

  onSensorSelect = (sensorId) => {
    this.setState({ sensor: sensorId });
    const current = this.state.current + 1;
    this.setState({ current: current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current: current });
  };

  handleDropdownChange = (value) => {
    this.setState({ assetType: value });
  };

  handleDropdownSensor = (value) => {
    this.setState({ sensor: value });
  }

  handleNotifyChange = (value) => {
    this.setState({ notify: value });
  };

  onChangeCheckbox = () => {
    this.setState({
      isChecked: !this.state.isChecked,
      isDescriptionrequired: !this.state.isDescriptionrequired
    });
  };

  scheduleHandler = () => {
    this.setState({ isRangePicker: true });
  };

  handleFileUpload = () => {
    this.setState({ takeSensorPhoto: false });
    const file = document.getElementById('image').files[0];
    if (file) {
      this.setState({ file: file.name });
      this.props.imageUploader(file);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.reduxHandler(values);
      }
    });
  };

  render() {
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { current, columnHeadings, columnKeys } = this.state;
    const times = ['last_update', 'power_on_date'];
    let steps = [], stepsList = [];

    if (!match.params.id) {
      steps.push(<div className="steps-content">
        <SensorStep
          activeSensors={this.props.activeSensors}
          onSensorSelect={this.onSensorSelect}
          columnHeadings={columnHeadings}
          columnKeys={columnKeys}
          times={times}
        />
      </div>);

      stepsList.push(<Step><SensorStep /></Step>);
    }

    steps.push([<div className="steps-content">
      <FirstStep
        getFieldDecorator={getFieldDecorator}
        handleDropdownChange={this.handleDropdownChange}
        handleDropdownSensor={this.handleDropdownSensor}
        sensors={this.props.sensors}
        next={this.next}
        prev={this.prev}
        id={this.props.id ? this.props.id : null}
      />
    </div>,
    <div className="steps-content">
      <SecondStep
        getFieldDecorator={getFieldDecorator}
        handleNotifyChange={this.handleNotifyChange}
        onChangeCheckbox={this.onChangeCheckbox}
        isChecked={this.state.isChecked}
        firstStepperValues={this.props.firstStepperValues}
        handleFileUpload={this.handleFileUpload}
        file={this.state.file}
        uploading={this.props.uploading}
        isRangePicker={this.state.isRangePicker}
        scheduleHandler={this.scheduleHandler}
        isDescriptionrequired={this.state.isDescriptionrequired}
        setRef={this.setRef}
        capture={this.capture}
        uploadedFile={this.props.uploadedFile}
        prev={this.prev}
        takePhotoHeight={this.state.takePhotoHeight}
        uploadSensorPhoto={this.state.uploadSensorPhoto}
        takeSensorPhoto={this.state.takeSensorPhoto}
      />
    </div>
    ]);

    stepsList.push([
      <Step><FirstStep /></Step>,
      <Step><SecondStep /></Step>
    ]);

    steps = _.flatten(steps);
    stepsList = _.flatten(stepsList);

    return (
      <Row id="MonitoringWizardView">
        <Card className="monitoring-wizard-card">
          <div>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Steps current={current}>
                {stepsList}
              </Steps>
              {steps[current]}
              <Divider />
            </Form>
          </div>
        </Card>
      </Row>
    );
  }
}

MonitoringWizardView.propTypes = {};

export default Form.create()(MonitoringWizardView);

const SensorStep = ({ activeSensors, onSensorSelect, columnHeadings, columnKeys, times }) => {
  return (
    <Row>
      {activeSensors && activeSensors.length === 0 ? (
        <EmptyData />
      ) : (
          <Table responsive>
            <thead>
              <tr>
                {_.map(columnHeadings, (heading) => (
                  <th>{heading}</th>
                ))
                }
              </tr>
            </thead>
            {activeSensors && activeSensors &&
              <tbody>
                {_.map(activeSensors && activeSensors, (record) => (
                  <tr onClick={() => onSensorSelect(record.id)}>
                    {columnKeys.map(key => (
                      <td>
                        {key === 'is_active' ? record.is_active === true ? <Icon type="check" /> : <Icon type="stop" /> : null}
                        {times.includes(key) && moment(record[key]).tz("Europe/Berlin").format("DD-MM-YY HH:mm")}
                        {key !== 'is_active' && !times.includes(key) && record[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            }
          </Table>
        )}
    </Row>
  )
};

const FirstStep = ({ getFieldDecorator, next, prev, handleDropdownChange, handleDropdownSensor, id, sensors }) => {
  return (
    <div className="steps-header">
      <label><b>Asset Type</b></label>
      <Row>
        <Form.Item>
          {getFieldDecorator('asset_type', {
            initialValue: ['Pallet'],
          })(
            <Select
              id="asset_type"
              className="asset-type-dropdown"
              onChange={handleDropdownChange}
            >
              {ASSET_TYPES && ASSET_TYPES.map((assetType) => (
                <Option value={assetType}>{assetType}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Row>
      <Row className="min-max-temperature" gutter={25}>
        <Col lg={12}>
          <label><b>Limit Minimum(℃)</b></label>
          <Form.Item>
            {getFieldDecorator('min', {
            })(
              <Input placeholder="Min" type="text" id="min" />
            )}
          </Form.Item>
        </Col>
        <Col lg={12}>
          <label><b>Limit Maximum(℃)</b></label>
          <Form.Item>
            {getFieldDecorator('max', {
            })(
              <Input placeholder="Max" type="text" id="max" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <label><b>Monitoring Name</b></label>
        <p>Each monitoring task should be assigned a name for easier identification later on. This can be an ID for future reference, e.g. task number.</p>
        <Form.Item>
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Monitoring name is required!', },
            ],
          })(
            <Input placeholder="Monitoring Name" id="name" />
          )}
        </Form.Item>
      </Row>
      <Row>
        <label><b>Comments</b></label>
        <p>A description text for your monitoring task.</p>
        <Form.Item>
          {getFieldDecorator('comments', {
            rules: [
              { pattern: new RegExp(COMMENTS_REGEX.regex), message: COMMENTS_REGEX.regexMessage }
            ],
          })(
            <TextArea id="comments" rows={4}></TextArea>
          )}
        </Form.Item>
      </Row>
      <Row gutter={25}>
        <Col lg={12}>
          <label><b>Asset ID</b></label>
          <Form.Item>
            {getFieldDecorator('asset_id', {
            })(
              <Input placeholder="Asset ID" id="asset_id" />
            )}
          </Form.Item>
        </Col>
        <Col lg={12}>
          <label><b>Asset Name</b></label>
          <Form.Item>
            {getFieldDecorator('asset_name', {
            })(
              <Input placeholder="Asset Name" id="asset_name" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={25} className="steps-btn-row">
        {!id && (
          <Button
            htmlType="submit"
            onClick={() => prev()}
            className="steps-btn"
          >
            Previous Step
          </Button>
        )}

        <Button
          htmlType="submit"
          onClick={() => next()}
          className="steps-btn"
        >
          Next Step
        </Button>
      </Row>
    </div>
  );
}

const SecondStep = ({
  getFieldDecorator,
  handleNotifyChange,
  onChangeCheckbox,
  isChecked,
  handleFileUpload,
  file,
  uploading,
  isRangePicker,
  scheduleHandler,
  isDescriptionrequired,
  setRef,
  capture,
  uploadedFile,
  firstStepperValues,
  prev,
  takePhotoHeight,
  uploadSensorPhoto,
  takeSensorPhoto
}) => (
    <div className="step-2">
      <Row>
        <Col lg={24} className="submit-btn-holder">
          <Checkbox
            className="check-box-align"
            checked={isChecked}
            onChange={onChangeCheckbox}
          >
            Want to add place of sensor?
            {/* Picture of sensor placing mandatory on start */}
          </Checkbox>
        </Col>
        {isChecked && (
          <div>
            <Row gutter={25}>
              <Col lg={24} >
                <h2>Place sensor</h2>
                <p>Enter description of sensor position (optional).</p>
                <Form.Item>
                  {getFieldDecorator('sensor_position', {
                    rules: [
                      { required: isDescriptionrequired, message: 'Sensor position is required!', },
                      { pattern: new RegExp(SENSOR_DESCRIPTION_REGEX.regex), message: SENSOR_DESCRIPTION_REGEX.regexMessage }
                    ],
                  })(
                    <TextArea placeholder="Description of sensor" className="msg-box" autosize={{ minRows: 2, maxRows: 6 }} />
                  )}
                </Form.Item>
                <p>Take picture of sensor position (optional, max one photo)</p>
              </Col>
            </Row>

            <Row className="photo-btn-holder" gutter={25}>
              {/* <Col lg={12}> */}
                {/* <Button onClick={capture} disabled={uploading ? true : false} className="photo-btn align-btn"><Icon type="camera" /> Take a Photo</Button> */}
              {/* </Col> */}
              <Col lg={12} >
                <Form.Item>
                  {getFieldDecorator('sensor_picture', {
                    rules: [
                      { required: uploadSensorPhoto, message: 'Sensor Picture/ Take a photo is required!', },
                    ],
                  })(
                    <Button className="photo-btn" onClick={() => $('#image').click()}>
                      <Input
                        id="image"
                        type="file"
                        onChange={() => handleFileUpload()}
                        hidden
                        accept="image/png, image/jpeg"
                      />
                      <Icon type="upload" /> Click to Upload
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {!file &&
              <Row className="webcam">
                <Form.Item>
                {getFieldDecorator('take_picture', {
                  })(
                <Webcam
                  audio={false}
                  height={takePhotoHeight}
                  ref={setRef}
                  screenshotFormat="image/jpeg"
                  width={720}
                  videoConstraints={{ facingMode: { exact: "environment" } }}
                />
                )}
                </Form.Item>
              </Row>
            }
            {file && uploadedFile && uploadedFile.url &&
              <Row className="webcam">
                <img src={uploadedFile.url} />
              </Row>
            }
            <Row className="uploading-image" type="flex" justify="center" align="middle">
              {uploading && (
                <Spin className="file-loader" />
              )}
              {file && !uploading && (
                <p><b>{file}</b></p>
              )}
            </Row>
          </div>
        )}
      </Row>
      <h2>Notifications</h2>
      {firstStepperValues && !firstStepperValues.min && !firstStepperValues.max && (
        <Alert message="No temperature limit set!" type="warning" />
      )}
      <p>If temperature exceeds the limits, a warning is sent to the following contact</p>
      <Row gutter={25}>
        <Col lg={12}>
          <label><b>Email Address </b></label>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { pattern: new RegExp(EMAIL_REGEX.regex), message: EMAIL_REGEX.regexMessage }
              ],
            })(
              <Input placeholder="Email address" id="email" />
            )}
          </Form.Item>
        </Col>
        <Col lg={12}>
          <label><b>Phone Number</b></label>
          <Form.Item>
            {getFieldDecorator('phone', {
              rules: [
                { pattern: new RegExp(NUMBER_REGEX.regex), message: NUMBER_REGEX.regexMessage }
              ],
            })(
              <Input placeholder="Phone Number" id="phone" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row className="bottom-btn-holder">
        <Col lg={24} className="select-holder">
          <label><b>Notify, if no signal for</b></label>
          <Form.Item>
            {getFieldDecorator('notify', {
              initialValue: ['0']
            })(
              <Select id="notify" placeholder="Select notify time" onChange={handleNotifyChange} >
                <Option value="0">Disabled</Option>
                <Option value="1">1 hour</Option>
                <Option value="2">2 hour</Option>
                <Option value="4">4 hour</Option>
                <Option value="8">8 hour</Option>
                <Option value="24">1 day</Option>
                <Option value="48">2 days</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row className="start-now-holder">
        <Col lg={24}>
          <Button type="primary" htmlType="submit">Start Now</Button>
        </Col>
      </Row>
      <Divider>OR</Divider>
      <h2>Schedule Monitoring</h2>
      <Row className="start-now-holder">
        <Col lg={24}>
          <Form.Item>
            {getFieldDecorator('rangePicker', {
              rules: [
                { required: isRangePicker, message: 'Start and end date is required!', },
              ],
            })(
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                placeholder={['Start Time', 'End Time']}
                className="date-picker"
              />
            )}
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Button type="primary" htmlType="submit" onClick={scheduleHandler}>Schedule</Button>
        </Col>
      </Row>
      <Row>
        <Button
          type="primary"
          onClick={() => prev()}
          className="steps-btn prev-btn"
        >
          Previous Step
        </Button>
      </Row>
    </div>
  );

