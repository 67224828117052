/**
 *
 * DashboardDevicesView component class
 *
 */

// Packages
import React from "react";
import { Row, Col, Radio, Input, Button, Popover, Checkbox, Icon, Pagination, Select } from "antd";
import { Table } from 'reactstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import moment from 'moment';

// styles
import "./sensors-view.css";

// Components
import EmptyData from "../EmptyData";

// Utils
import { SENSORS_COLUMNS } from "../../utils/columns";

const { Option } = Select;

class SensorsView extends React.Component {
  state = {
    mode: 'null',
    keyword: '',
    current: 1,
    checked: true,
    visible: false,
    columnKeys: ['name', 'company_name', 'last_update', 'last_gateway_id', 'signal_strength', 'log_interval', 'log_memory', 'power_on_date', 'last_battery', 'mac'],
    fieldsOrderArr: ['asc', 'asc', 'asc', 'asc', 'asc', 'asc'],
  };

  handleModeChange = (value, key) => {
    const { keyword } = this.state;
    this.setState({ mode: value });
    this.props.searchHandler(key, keyword, value, localStorage.userId);
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleCheckbox = (title, key, index) => {
    const { headings, keys } = this.props;

    if (headings.includes(title)) {
      const index = headings.indexOf(title);
      const keyIndex = keys.indexOf(key);
      headings.splice(index, 1);
      keys.splice(keyIndex, 1);
      this.props.saveColumnsHandler(headings, keys);
    } else {
      headings.splice(index, 0, title);
      keys.splice(index, 0, key);
      this.props.saveColumnsHandler(headings, keys);
    }

    this.setState({
      visible: !this.state.visible
    });
  };

  onChange = (page) => {
    this.setState({ current: page });
    this.props.reduxPageHandler(page);
  };

  headingHandler = (key) => {
    const { columnKeys, fieldsOrderArr } = this.state;
    const { sensors } = this.props;
    let index = null, order = null, sortedArr = [];
    index = columnKeys.indexOf(key);
    order = fieldsOrderArr[index];
    order === 'asc' ? fieldsOrderArr[index] = 'desc' : fieldsOrderArr[index] = 'asc';
    sortedArr = _.orderBy(sensors, [key], [order === 'asc' ? 'desc' : 'asc']);
    this.props.columnSortingHandler(sortedArr);
  };

  handleKeyUp = (e, key) => {
    const { mode } = this.state;
    if (e.keyCode === 13) {
      this.setState({ keyword: e.target.value });
      this.props.searchHandler(key, e.target.value, mode, localStorage.userId);
    }
    if (e.target.value.length === 0) {
      this.props.searchHandler(key, e.target.value, mode, localStorage.userId);
    }
  };

  render() {
    const { mode } = this.state;
    const { sensors, sensorsCount, headings, keys } = this.props;
    const times = ['last_update', 'power_on_date'];

    return (
      <Row id="DashboardDevicesView">
        <Row className="radio-buttons" gutter={25}>
          <Col xs={24} md={24} lg={8}>
            <Select
              defaultValue={'null'}
              className="asset-type-dropdown"
              value={mode}
              onChange={value => this.handleModeChange(value, 'active')}
            >
              <Option value="null">All - Monitored & Free</Option>
              <Option value="monitored">Monitored</Option>
              <Option value="free">Free</Option>
            </Select>
          </Col>
          <Col lg={8} md={20} sm={16} xs={24}></Col>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Input className="device-input" addonBefore={'Sensor'}
              onKeyUp={(e) => this.handleKeyUp(e, 'name')}
            />
          </Col>
        </Row>
        <Row className="device">
          <Col lg={24} className="col-btn-holder">
            <Popover
              content={<ColumnPopover handleCheckbox={this.handleCheckbox} columns={headings} />}
              trigger="click"
              visible={this.state.visible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Button className="column-button">Columns</Button>
            </Popover>
          </Col>
        </Row>
        <Row>
          {sensors && sensors.length === 0 ? (
            <EmptyData />
          ) : (
              <Table responsive>
                <thead>
                  <tr>
                    {_.map(headings, (heading, index) => (
                      <th key={index} onClick={() => this.headingHandler(keys[index])}>
                        <Row>
                          {heading}
                          <img
                            src={require('../../assets/images/sort-solid.svg')}
                            className="sorting-icon"
                          />
                        </Row>
                      </th>
                    ))
                    }
                  </tr>
                </thead>
                {sensors && sensors &&
                  <tbody>
                    {_.map(sensors && sensors, (record) => {
                      if (!record) return null;
                      return <tr key={record.id} onClick={() => this.props.history.push(`/sensor/${record.id}`)}>
                        {keys.map((key, i) => (
                          <td key={i}>
                            {key === 'company_name' && record.company && record.company.name}
                            {key === 'is_active' ? record.is_active === true ? <Icon type="check" /> : <Icon type="stop" /> : null}
                            {key === 'last_gateway_id' && record.last_gateway && record.last_gateway.mac}
                            {key === 'log_interval' && record.log_interval ? `${record.log_interval / 60} min` : ''}
                            {key === 'log_memory' && record.log_interval && record.log_capacity ? `${(record.log_interval * record.log_capacity) / 3600} h` : ''}
                            {times.includes(key) && moment(record[key]).tz("Europe/Berlin").format("DD-MM-YY HH:mm")}
                            {key !== 'is_active' && key !== 'last_gateway_id' && key !== 'log_interval' && key !== 'log_memory' && !times.includes(key) && record[key]}
                          </td>
                        ))}
                      </tr>
                    })}
                  </tbody>
                }
              </Table>
            )}
        </Row>
      </Row>
    );
  }
}

SensorsView.propTypes = {};

export default SensorsView;

const ColumnPopover = ({ handleCheckbox, columns }) => (
  <div>
    {SENSORS_COLUMNS.map((column, i) => (
      <Row key={i}>
        <Checkbox
          checked={columns.includes(column.title) ? true : false}
          onChange={() => handleCheckbox(column.title, column.dataIndex, i)}
        >
          {column.title}
        </Checkbox>
      </Row>
    ))}
  </div>
);
