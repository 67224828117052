import gql from "graphql-tag";

const firm = `
  name
  address
  city
`;

const location = `
  location_id
  company_id
  address
  details
  latitude
  longitude
`;

const gateway = `
  id
  company_id
  locaton_id
  last_battery
  is_active
  is_muted
  mac
  gateway_id
  token
  last_location
  location_details
  positon_details
  position_photo
  createdAt
  updatedAt
  company {
    ${firm}
  }
  location {
    ${location}
  }
`;

export const GET_GAYEWAYS = gql`
  query getGateways(
    $pageNo: Int!
    $userId: Int
  ) {
    getGateways(
      pageNo: $pageNo
      userId: $userId
    ) {
      gateways {
        ${gateway}
      }
      count
    } 
  }
`;

export const GET_SPECIFIC_GATEWAY = gql`
  query getSpecificGateway(
    $gatewayId: Int!
  ) {
    getSpecificGateway(
      gatewayId: $gatewayId
    ) {
     message
     success
     gateway {
       ${gateway}
     }
    } 
  }
`;

export const UPDATE_GATEWAY = gql`
  mutation updateGateway(
    $gatewayId: Int!
    $company_id: Int
    $location_id: String
    $location_details: String
    $position_details: String
    $position_photo: String
  ) {
    updateGateway(
      gatewayId: $gatewayId
      company_id: $company_id
      location_id: $location_id
      location_details: $location_details
      position_details: $position_details
      position_photo: $position_photo
    ) {
      message
      success
    } 
  }
  `;

export const DELETE_GATEWAY = gql`
  mutation deleteGateway(
    $gatewayId: Int!
    $pageNo: Int!
  ) {
    deleteGateway(
      gatewayId: $gatewayId
      pageNo: $pageNo
    ) {
      gateways {
        ${gateway}
      }
      count
    } 
  }
`;

export const GET_FILTERED_GATEWAYS = gql`
  query getFilteredGateways(
    $keyword: String
    $userId: Int
  ) {
    getFilteredGateways(
      keyword: $keyword
      userId: $userId
    ) {
      gateways {
        ${gateway}
      }
      count
    } 
  }
`;

export const RESET_GATWAY_PASSWORD = gql`
  mutation resetGatewayPassword(
    $gatewayId: Int!
    $password: String!
  ) {
    resetGatewayPassword(
      gatewayId: $gatewayId
      password: $password
    ) {
      success
      message
    } 
  }
`;

export const CHANGE_GATEWAY_ROLE = gql`
  mutation changeGatewayRole(
    $userId: Int!
    $role: String!   
  ) {
    changeGatewayRole(
      userId: $userId
      role: $role
    ) {
      ${gateway}
    } 
  }
`;

export const MUTE_GATEWAY = gql`
  mutation muteGateway(
    $gatewayId: Int!
    $isMuted: Boolean
    $pageNo: Int
  ) {
    muteGateway(
      gatewayId: $gatewayId
      isMuted: $isMuted
      pageNo: $pageNo
    ) {
      gateways {
        ${gateway}
      }
      count
    }
  } 
`;
