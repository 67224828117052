/**
 *
 * LocationView component class
 *
 */
// Packages
import React from "react";
import { Row, Col, Tooltip, Button, Icon, Divider, Popconfirm } from "antd";
import _ from "lodash";
import moment from "moment";
import { Table } from 'reactstrap';

// Components
import EmptyData from "../EmptyData";

// styles
import "./location-view.css";

/* eslint-disable react/prefer-stateless-function */
class LocationView extends React.Component {

  state = {
    current: 1,
    columnHeadings: ['Location ID', 'Company', 'Address', 'Details', 'Latitude', 'Longitude', 'Last Updated', 'Actions'],
    columnKeys: ['location_id', 'company', 'address', 'details', 'latitude', 'longitude', 'updatedAt'],
  };

  onChange = (page) => {
    this.setState({ current: page });
    this.props.reduxPageHandler(page);
  };

  render() {
    const { getLocationsResponse } = this.props;
    const { columnHeadings, columnKeys } = this.state;
    const times = ['updatedAt'];

    return (
      <Row id="LocationView">
        <Row>
          <Col lg={12}>
            <h2>Locations</h2>
          </Col>
          <Col lg={12}>
            <Tooltip placement="bottom" title={"Add Location"}>
              <Button
                className="add-location-button"
                onClick={() => this.props.history.push('/location/add-location')}
              >
                <Icon type="plus-circle" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Divider />
        <Row>
          {getLocationsResponse && getLocationsResponse.locations && getLocationsResponse.locations.length === 0 ? (
            <EmptyData />
          ) : (
              <Table responsive>
                <thead>
                  <tr>
                    {_.map(columnHeadings, (heading, i) => (
                      <th key={i}>{heading}</th>
                    ))
                    }
                  </tr>
                </thead>
                {getLocationsResponse && getLocationsResponse.locations && getLocationsResponse.locations &&
                  <tbody>
                    {_.map(getLocationsResponse.locations && getLocationsResponse.locations, (record) => (
                      <tr key={record.id}>
                        {columnKeys.map((key, i) => {
                          if (key === 'company') {
                            return <td key={i}>{record[key].name}</td>
                          }
                          return (
                            <td key={i}>
                              {times.includes(key) && moment(record[key], 'DD-MM-YY HH:mm').tz("Europe/Berlin").format('DD-MM-YY HH:mm')}
                              {key !== 'is_active' && !times.includes(key) && record[key]}
                            </td>
                          )
                        })}
                        <td>
                          <Tooltip placement="bottom" title={"Edit Location"}>
                            <Icon
                              type="edit"
                              onClick={() => this.props.editLocationHandler(record.id)}
                            />
                          </Tooltip>
                          <Divider type="vertical" />
                          <Tooltip placement="bottom" title={"Delete Location"}>
                            <Popconfirm
                              title="Are you sure delete this location?"
                              onConfirm={() => this.props.deleteLocationHandler(record.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Icon
                                type="delete"
                              />
                            </Popconfirm>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
              </Table>
            )}
        </Row>
      </Row>
    );
  }
}

LocationView.propTypes = {};

export default LocationView;
